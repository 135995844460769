<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card class="ma-4">
      <v-data-table
        v-model="unlinkSelected"
        :search="search"
        :headers="services.linkHeaders"
        :items="services.unlinkedServices"
        :single-select="false"
        item-key="service_id"
        show-select
        class="elevation-0 pa-5"
        dense
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              title="link selected customer services"
              dark
              @click="linkButtonEvent"
            >
              Link
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.service_type`]="{ item }">
          <service-type :service-type="item.service_type" />
        </template>
        <template v-slot:no-data>
          <span color="primary">All Active Services are linked</span>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="ma-4">
      <v-data-table
        v-model="linkSelected"
        :search="search"
        :headers="services.linkHeaders"
        :items="services.linkedServices"
        :single-select="false"
        item-key="service_id"
        show-select
        class="elevation-0 pa-5"
        dense
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              title="link selected customer services"
              dark
              @click="unlinkButtonEvent"
            >
              Unlink
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.service_type`]="{ item }">
          <service-type :service-type="item.service_type" />
        </template>
        <template v-slot:no-data>
          <span color="primary">All Active Services are linked</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    ServiceType: () => import('@/components/core/ServiceType')
  },
  data() {
    return {
      search: '',
      unlinkSelected: [],
      linkSelected: [],
      overlay: false
    };
  },
  computed: {
    ...mapState(['services', 'crm_id', 'ticketing', 'app_user'])
  },
  methods: {
    ...mapActions([
      'linkServicesAction',
      'unlinkServicesAction',
      'storeLinkedServices'
    ]),
    createButtonEvent() {},
    async linkButtonEvent() {
      this.overlay = true;
      const data = [
        ...this.unlinkSelected.map(el => {
          return {
            service_id: el.service_id,
            crm_id: this.crm_id
          };
        })
      ];
      await this.linkServicesAction(data);
      await this.storeLinkedServices(this.crm_id);
      this.services.linkedServices.forEach(item => {
        if (item.service_type == 'TICKET') {
          this.$store.state.disableHierarchyTab = false;
          this.ticketing.isProfiling = true;
        }
      });
      this.unlinkSelected = [];
      this.overlay = false;
    },
    async unlinkButtonEvent() {
      this.overlay = true;
      const data = [
        ...this.linkSelected.map(el => {
          return {
            service_id: el.service_id,
            crm_id: this.crm_id
          };
        })
      ];
      await this.unlinkServicesAction(data);
      await this.storeLinkedServices(this.crm_id);
      this.services.linkedServices.forEach(item => {
        if (item.service_type == 'TICKET') {
          this.$store.state.disableHierarchyTab = false;
          this.ticketing.isProfiling = true;
        } else {
          this.$store.state.disableHierarchyTab = true;
          this.ticketing.isProfiling = false;
        }
      });
      this.overlay = false;
    }
  }
};
</script>
